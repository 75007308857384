var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.disabled)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","small":"","loading":_vm.loading}},on),[_vm._v(" "+_vm._s(_vm._f("t")('banner.action.create_item'))+" ")])]}}],null,false,1113053853)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":_vm.openPhotoManager}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("t")("photo_manager"))+" ")])],1),_vm._l((_vm.itemTypes),function(type){return _c('v-list-item',{key:type,attrs:{"dense":""},on:{"click":function($event){return _vm.addNewItem(type)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("t")(("banner.type." + type)))+" ")])],1)})],2)],1):_vm._e(),_c('invisible-photo-uploader',{ref:"photoUploader",attrs:{"multiple":"","type":"banner"},on:{"appendPhoto":_vm.appendPhoto,"uploadAllCompleted":_vm.uploadAllPhotoCompleted,"onLoadingChange":_vm.onPhotoUploaderLoadingChange}}),_c('div',{staticClass:"elevation-4 px-4 py-8 my-4"},[(_vm.items.length == 0)?_c('div',[_vm._v(" "+_vm._s(_vm._f("t")('data.empty'))+" ")]):_vm._e(),_c('v-draggable',{staticClass:"d-flex my-4 overflow-auto",attrs:{"handle":".banner-item-draggable","forceFallback":true},on:{"end":_vm.sortEnd},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},_vm._l((_vm.items),function(item,index){return _c(_vm.getBannerItemComponent(item.instance_type),{key:("banner-item-" + index),tag:"component",attrs:{"disabled":_vm.disabled,"item":item,"index":index},on:{"updateItem":_vm.updateItem,"refresh":function($event){return _vm.$emit('refresh')}},scopedSlots:_vm._u([(!_vm.disabled)?{key:"actions",fn:function(){return [_c('div',{staticClass:"banner-item-actions"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"banner-item-draggable",attrs:{"icon":"","color":"orange","small":""}},on),[_c('v-icon',[_vm._v("fa fa-bars")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("t")('action.move')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){return _vm.removeItem(index)}}},on),[_c('v-icon',[_vm._v("fa fa-trash")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("t")('action.remove')))])])],1)]},proxy:true}:null],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }